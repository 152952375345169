import './Footer.css'
import resume from '../assets/Noel Santillan Resume_01_2022_V2.pdf'
import { useRef, useState } from 'react';
import { useIsMobile } from '../hooks/useIsMobile';

export default function Footer() {
    const { isMobile } = useIsMobile()
 
    const [link, setLink] = useState('');
    const aRef = useRef()

    const handleIndeedClick =()=>{
        const _link = 'https://my.indeed.com/p/noels-dv8uue3';
        // setLink(_link)
        aRef.current.href = _link
        aRef.current.click()
    }
    const handleLinkedInClick =()=>{
        const _link = 'https://www.linkedin.com/in/noel-santillan-415074182/';
        // setLink(_link)
        aRef.current.href = _link
        aRef.current.click()

    }
    const handleResumeClick =()=>{
        // setLink(resume)
        aRef.current.href = resume
        aRef.current.click()
    }

    return (
        <div className='footer-wrapper flex-row-center-center'>
            <a ref={aRef} href={link} target="_blank" rel="noopener noreferrer" style={{display: 'none'}}></a>
            <div className="footer-container flex-row-center-even">
                
                <svg onClick={handleIndeedClick} 
                    className='indeed' 
                    // width="75" 
                    // height="20" 
                    viewBox={isMobile ? "0 0 13.8 20.1" : "0 0 75 20" }
                    fill="none" 
                    xmlns="http://www.w3.org/2000/svg" 
                    focusable="false" 
                    aria-hidden="true"
                >
                    <g fill='#003A9B'>
                        {isMobile && <>
                            <path className="st0" d="M6.5,18.1v-7.3c0.2,0,0.4,0,0.6,0c1,0,1.9-0.3,2.8-0.7v8c0,0.7-0.2,1.2-0.5,1.5c-0.3,0.3-0.7,0.5-1.2,0.5 c-0.5,0-0.9-0.2-1.2-0.5C6.7,19.2,6.5,18.7,6.5,18.1z"/>
                            <path className="st0" d="M6.6,0.6c2.1-0.7,4.5-0.7,6.3,0.8c0.3,0.3,0.7,0.7,0.9,1.2C14,3.1,13.1,2.5,13,2.4c-0.6-0.4-1.2-0.7-1.8-0.9 c-3.6-1.1-6.9,0.9-9,3.9C1.3,6.7,0.7,8.1,0.2,9.6C0.2,9.8,0.1,10,0.1,10.1C0,10.3,0,9.7,0,9.7C0.1,9,0.2,8.4,0.4,7.8 C1.4,4.5,3.5,1.8,6.6,0.6L6.6,0.6z"/>
                            <path className="st0" d="M10.7,6.4c0,1.4-1.2,2.5-2.6,2.4c-1.3,0-2.4-1.1-2.4-2.4c0-1.4,1.1-2.5,2.4-2.6s2.5,1.1,2.6,2.4 C10.7,6.3,10.7,6.4,10.7,6.4z"/>
                        </>}
                        {!isMobile && <>
                            <path className="st0" d="M74.6,3.5C74.3,3.2,74,3,73.5,3s-0.8,0.2-1.1,0.5C72.2,3.8,72,4.3,72,4.9v4.5c-0.6-0.6-1.2-1.1-1.8-1.4 c-0.4-0.2-0.9-0.3-1.4-0.4c-0.3,0-0.6-0.1-0.9-0.1c-1.6,0-2.8,0.5-3.8,1.6c-1,1.1-1.4,2.6-1.4,4.5c0,0.9,0.1,1.7,0.4,2.5 c0.2,0.8,0.6,1.4,1.1,2c0.5,0.6,1,1,1.7,1.3c0.7,0.3,1.4,0.5,2.1,0.5c0.3,0,0.7,0,1-0.1c0.2,0,0.4-0.1,0.6-0.1 c0.5-0.2,0.9-0.4,1.3-0.7c0.5-0.3,0.9-0.7,1.3-1.1v0.3c0,0.6,0.1,1,0.4,1.3c0.3,0.3,0.6,0.5,1.1,0.5c0.4,0,0.8-0.1,1.1-0.4 c0.3-0.3,0.4-0.7,0.4-1.3V4.8C75,4.2,74.9,3.8,74.6,3.5z M71.4,15.9c-0.3,0.6-0.6,1-1.1,1.3c-0.5,0.3-1,0.4-1.5,0.4h0 c-0.5,0-1.1-0.1-1.5-0.4c-0.5-0.3-0.8-0.7-1.1-1.3c-0.3-0.6-0.4-1.3-0.4-2.1c0-0.8,0.1-1.5,0.4-2.1c0.2-0.6,0.6-1.1,1.1-1.4 c0.5-0.3,1-0.5,1.5-0.5h0c0.5,0,1,0.2,1.5,0.5c0.5,0.3,0.8,0.8,1.1,1.3c0.3,0.6,0.4,1.3,0.4,2.1C71.9,14.6,71.7,15.4,71.4,15.9z"/>
                            <path className="st0" d="M60.8,15.8c-0.3,0-0.5,0.1-0.7,0.2c-0.4,0.4-0.7,0.6-1,0.9c-0.2,0.2-0.5,0.4-0.8,0.6c-0.3,0.2-0.6,0.3-0.9,0.4 c-0.3,0.1-0.7,0.1-1,0.1c-0.1,0-0.2,0-0.2,0c-0.5,0-0.9-0.2-1.3-0.4c-0.5-0.3-0.8-0.7-1.1-1.2c-0.3-0.5-0.4-1.2-0.4-1.8h6.1 c0.8,0,1.5-0.1,1.9-0.3c0.5-0.2,0.7-0.7,0.7-1.5c0-0.8-0.2-1.6-0.7-2.5c-0.4-0.8-1.1-1.4-2-2c-0.9-0.5-1.9-0.8-3.2-0.8h-0.1 c-0.9,0-1.7,0.2-2.5,0.4c-0.7,0.3-1.4,0.7-2,1.3c-0.5,0.6-0.9,1.3-1.2,2c-0.3,0.8-0.4,1.7-0.4,2.5c0,1.9,0.6,3.4,1.7,4.6 c1.1,1.1,2.5,1.6,4.4,1.7c0.1,0,0.2,0,0.3,0c0.9,0,1.7-0.1,2.3-0.3c0.7-0.2,1.2-0.5,1.7-0.8c0.4-0.3,0.8-0.7,1-1.1 c0.2-0.4,0.3-0.7,0.3-1c0-0.3-0.1-0.6-0.3-0.7C61.4,15.9,61.1,15.8,60.8,15.8z M54.2,10.5c0.5-0.5,1.1-0.8,1.9-0.8h0 c0.8,0,1.5,0.3,2,0.8c0.5,0.5,0.8,1.3,0.9,2.4h-5.7C53.4,11.8,53.7,11,54.2,10.5z"/>
                            <path className="st0" d="M48.2,15.8L48.2,15.8c-0.3,0-0.5,0.1-0.7,0.2c-0.4,0.4-0.7,0.6-1,0.9c-0.2,0.2-0.5,0.4-0.8,0.6 c-0.3,0.2-0.6,0.3-0.9,0.4c-0.3,0.1-0.7,0.1-1,0.1c-0.1,0-0.2,0-0.2,0c-0.5,0-0.9-0.2-1.3-0.4c-0.5-0.3-0.9-0.7-1.1-1.2 c-0.3-0.5-0.4-1.1-0.4-1.8h6.2c0.8,0,1.5-0.1,1.9-0.3c0.4-0.2,0.7-0.7,0.7-1.5c0-0.8-0.2-1.6-0.7-2.5c-0.4-0.8-1.1-1.5-2-2 c-0.9-0.5-1.9-0.8-3.2-0.8h-0.1c-0.9,0-1.7,0.2-2.5,0.4c-0.7,0.3-1.4,0.7-2,1.3c-0.5,0.6-1,1.3-1.2,2c-0.3,0.8-0.4,1.7-0.4,2.5 c0,1.9,0.6,3.4,1.7,4.6c1.1,1.1,2.5,1.6,4.4,1.7c0.1,0,0.2,0,0.3,0c0.9,0,1.7-0.1,2.3-0.3c0.7-0.2,1.2-0.5,1.7-0.8 c0.5-0.3,0.8-0.7,1-1.1c0.2-0.4,0.3-0.7,0.3-1c0-0.3-0.1-0.6-0.3-0.7C48.7,15.9,48.5,15.8,48.2,15.8z M41.6,10.5 c0.5-0.5,1.1-0.8,1.9-0.8h0c0.8,0,1.5,0.3,2,0.8c0.5,0.5,0.8,1.3,0.9,2.4h-5.7C40.8,11.8,41.1,11,41.6,10.5z"/>
                            <path className="st0" d="M6.5,18v-7.3c0.2,0,0.4,0,0.6,0c1,0,1.9-0.3,2.8-0.7v8c0,0.7-0.2,1.2-0.5,1.5C9.2,19.8,8.8,20,8.2,20 c-0.5,0-0.9-0.2-1.2-0.5C6.7,19.1,6.5,18.6,6.5,18z"/>
                            <path className="st0" d="M36.1,3.5L36.1,3.5C35.9,3.2,35.5,3,35.1,3c-0.5,0-0.8,0.2-1.1,0.5c-0.3,0.3-0.4,0.8-0.4,1.4v4.5 C33,8.8,32.4,8.3,31.7,8c-0.4-0.2-0.9-0.3-1.4-0.4c-0.3,0-0.6-0.1-0.9-0.1c-1.6,0-2.8,0.5-3.8,1.6c-1,1.1-1.4,2.6-1.4,4.5 c0,0.9,0.1,1.7,0.4,2.5c0.2,0.7,0.6,1.4,1.1,2c0.5,0.6,1,1,1.7,1.3c0.7,0.3,1.4,0.5,2.1,0.5c0.3,0,0.7,0,1-0.1 c0.2,0,0.4-0.1,0.6-0.1c0.5-0.2,0.9-0.4,1.3-0.7c0.4-0.3,0.8-0.7,1.3-1.1v0.3c0,0.6,0.1,1,0.4,1.3c0.3,0.3,0.6,0.5,1.1,0.5 c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.4-0.7,0.4-1.3V4.8C36.5,4.2,36.4,3.8,36.1,3.5z M33,15.9L33,15.9c-0.3,0.6-0.6,1-1.1,1.3 c-0.4,0.3-1,0.4-1.5,0.4h0c-0.5,0-1-0.1-1.5-0.4c-0.5-0.3-0.8-0.7-1.1-1.3c-0.3-0.6-0.4-1.3-0.4-2.1c0-0.8,0.1-1.5,0.4-2.1 c0.3-0.6,0.6-1.1,1.1-1.4c0.4-0.3,1-0.5,1.5-0.5h0c0.5,0,1,0.2,1.5,0.5c0.5,0.3,0.8,0.8,1.1,1.3c0.3,0.6,0.4,1.3,0.4,2.1 C33.4,14.6,33.3,15.4,33,15.9z"/>
                            <path className="st0" d="M15,9.3v0.4c0.6-0.7,1.2-1.2,1.8-1.6c0.7-0.3,1.4-0.5,2.3-0.5c0.8,0,1.6,0.2,2.2,0.5c0.6,0.3,1.1,0.8,1.5,1.5 c0.2,0.4,0.3,0.8,0.4,1.2c0.1,0.4,0.1,1,0.1,1.7v5.7c0,0.6-0.2,1.1-0.4,1.4c-0.3,0.3-0.7,0.5-1.1,0.5c-0.5,0-0.8-0.2-1.1-0.5 c-0.3-0.3-0.4-0.8-0.4-1.4V13c0-1-0.1-1.8-0.4-2.3c-0.3-0.5-0.9-0.8-1.7-0.8c-0.6,0-1.1,0.2-1.5,0.5c-0.5,0.3-0.8,0.8-1,1.4 c-0.2,0.5-0.2,1.3-0.2,2.6v3.8c0,0.6-0.2,1.1-0.4,1.4c-0.3,0.3-0.7,0.5-1.1,0.5c-0.5,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.4-0.8-0.4-1.4 V9.3c0-0.6,0.1-1,0.4-1.3c0.3-0.3,0.6-0.4,1.1-0.4c0.3,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.3,0.5,0.6C14.9,8.6,15,8.9,15,9.3z"/>
                            <path className="st0" d="M6.6,0.5c2.1-0.7,4.5-0.7,6.3,0.8c0.3,0.3,0.7,0.7,0.9,1.2C14,3,13.1,2.4,13,2.3c-0.6-0.4-1.2-0.7-1.8-0.9 c-3.6-1.1-6.9,0.9-9,3.9C1.3,6.6,0.7,8,0.2,9.5c-0.1,0.2-0.1,0.4-0.2,0.5C0,10.2,0,9.6,0,9.6c0.1-0.6,0.2-1.3,0.4-1.9 C1.4,4.4,3.5,1.7,6.6,0.5L6.6,0.5z"/>
                            <path className="st0" d="M10.7,6.4c0,1.4-1.2,2.5-2.6,2.4c-1.3,0-2.4-1.1-2.4-2.4c0-1.4,1.1-2.5,2.4-2.6s2.5,1.1,2.6,2.4 C10.7,6.3,10.7,6.3,10.7,6.4z"/>
                        </>}
                    </g>
                </svg>

                <svg onClick={handleLinkedInClick}
                    className='linkedin' 
                    xmlns="http://www.w3.org/2000/svg" 
                    xmlnsXlink="http://www.w3.org/1999/xlink" 
                    viewBox={isMobile ? "0 0 21 21" : "0 0 85 20"}
                    version="1.1" 
                >
                    <g fill='#0A66C2'>
                        {isMobile && 
                            <path className="st0" d="M19.5,0H1.6C0.7,0,0,0.7,0,1.5v18C0,20.3,0.5,21,1.3,21h17.9c0.9,0,1.8-0.7,1.8-1.5v-18C21,0.7,20.3,0,19.5,0z M6,18H3V8h3V18z M4.5,6.4c-1,0-1.9-0.8-1.9-1.9s0.8-1.9,1.9-1.9s1.9,0.8,1.9,1.9S5.5,6.4,4.5,6.4z M18,18h-3v-5 c0-1.3-0.5-2.5-1.8-2.5c-1.5,0-2.2,1-2.2,2.7V18H8V8h2.8v1.4h0c0.4-0.8,1.7-1.6,3.3-1.6c3,0,3.9,1.6,3.9,4.6V18z"/>
                        }
                        {!isMobile && <>
                            <path className="st0" d="M82.5,0H64.6C63.7,0,63,0.7,63,1.5v18c0,0.8,0.5,1.5,1.3,1.5h17.9c0.9,0,1.8-0.7,1.8-1.5v-18 C84,0.7,83.3,0,82.5,0z M69,18h-3V8h3V18z M67.5,6.4c-1,0-1.9-0.8-1.9-1.9s0.8-1.9,1.9-1.9s1.9,0.8,1.9,1.9S68.5,6.4,67.5,6.4z M81,18h-3v-5c0-1.3-0.5-2.5-1.8-2.5c-1.5,0-2.2,1-2.2,2.7V18h-3V8h2.8v1.4h0c0.4-0.8,1.7-1.6,3.3-1.6c3,0,3.9,1.6,3.9,4.6V18z"/>
                            <path className="st2" d="M60,18h-2.8v-1.2h0c-0.6,0.7-1.7,1.3-3.5,1.3c-2.5,0-4.7-1.9-4.7-5.1c0-3.1,2.1-5.1,4.7-5.1 c1.6,0,2.6,0.6,3.2,1.3h0V3h3V18z M54.5,10.1c-1.7,0-2.7,1.2-2.7,2.8c0,1.6,1,2.9,2.7,2.9c1.7,0,2.7-1.2,2.7-2.9 C57.2,11.3,56.2,10.1,54.5,10.1L54.5,10.1z"/>
                            <path className="st2" d="M47.7,16.4c-0.7,0.9-2.2,1.7-4.5,1.7C40,18.1,38,16,38,12.8c0-2.9,1.8-4.9,5.2-4.9c3,0,4.8,1.9,4.8,5.3 c0,0.3-0.1,0.7-0.1,0.7h-7.1l0,0.3c0.2,0.9,0.8,1.9,2.3,1.9c1.3,0,2.2-0.7,2.6-1.3L47.7,16.4z M45.1,12c0-1.1-0.8-2.1-2-2.1 c-1.5,0-2.2,1.1-2.3,2.1H45.1z"/>
                            <polygon className="st2" points="38,8 34.5,8 31,12 31,3 28,3 28,18 31,18 31,13 34.7,18 38.2,18 34,12.5 	"/>
                            <path className="st2" d="M16,8h2.8v1.4h0c0.4-0.8,1.7-1.6,3.3-1.6c3,0,3.9,1.9,3.9,4.6V18h-3v-5c0-1.5-0.5-2.5-1.8-2.5 c-1.5,0-2.2,1.2-2.2,2.7V18h-3V8z"/>
                            <path className="st2" d="M11,18h3V8h-3V18z M12.5,6.3c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8S11.5,6.3,12.5,6.3z"/>
                            <polygon className="st2" points="3,3 0,3 0,18 9,18 9,15 3,15 	"/>
                        </>}
                    </g>
                </svg>
            
                <div onClick={handleResumeClick} className="resume  flex-row-center-even">
                    <svg aria-hidden="true" 
                        focusable="false" 
                        data-prefix="fas" 
                        data-icon="file-alt" 
                        xmlns="http://www.w3.org/2000/svg" 
                        viewBox="0 0 384 512">
                        <defs>
                            <linearGradient gradientTransform="rotate(90)" id="gradient" >
                                <stop  offset="0" stopColor="var(--startColor)" />
                                <stop  offset="0.1885" stopColor="var(--startColor)" />
                                <stop  offset="1" stopColor="var(--stopColor)" />
                            </linearGradient>
                        </defs>
                        <path  d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 236c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-64c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-72v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm96-114.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"></path>
                    </svg>
                    {!isMobile && 'Resume'}
                </div>

                {isMobile && <span >&copy;2023</span>}
                {!isMobile && <span >Noel Santillan &copy; 2023</span>}

            </div>
        </div>
    )
}




